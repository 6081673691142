import * as pdfjsLib from "pdfjs-dist/build/pdf";
import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.mjs";
import { PDFDocument, rgb } from "pdf-lib";
import { createCanvas } from "canvas";
import "./style.css";
import squareImg from "./assets/square.svg";
import circleImg from "./assets/circle.svg";
import trashImg from "./assets/trash.svg";
import pointerImg from "./assets/pointer.svg";
import nextImg from "./assets/next.svg";
import previousImg from "./assets/previous.svg";
import fingerPrintImg from "./assets/finger-print.svg";
import qrImg from "./assets/qr.svg";
import textImg from "./assets/text.svg"; // Add an icon for text
import lineImg from "./assets/line.svg"; // Add an icon for line
import freehandImg from "./assets/freehand.svg"; // Add an icon for line

/**
 * PDFMarker class for marking and masking PDF documents.
 */

class PDFMarker {
  /**
   * Constructor for the PDFMarker class.
   *
   * @param {string} pdfUrl - URL of the PDF document.
   * @param {string} containerId - ID of the container element to display the PDF.
   * @param {Object} maskData - Masking data for pre-existing markings.
   *
   */

  constructor(pdfUrl, containerId, maskData) {

    this.eventTarget = new EventTarget();
    /** 
     * @ignore
     */
    this.pdfDoc = null;
    /**
     * @ignore
     */

    this.pageNum = 1;
    /**
     * @ignore
     */

    this.container = document.getElementById(containerId);

    /**
     * @ignore
     */
    this.container.innerHTML = "";
    /**
     * @ignore
     */
    this.canvas = document.createElement("canvas");
    /**
     * @ignore
     */
    this.canvas2 = document.createElement("canvas");
    /**
     * @ignore
     */
    this.toolbar = this.createToolbar();
    /**
     * @ignore
     */
    this.pdfContext = this.canvas.getContext("2d");
    /**
     * @ignore
     */
    this.pdfContext2 = this.canvas2.getContext("2d");
    /**
     * @ignore
     */
    this.drawingMode = null;

    /**
     * @ignore
     */
    this.drawing = false;
    /**
     * @ignore
     */
    this.startX = 0;
    /**
     * @ignore
     */
    this.startY = 0;
    /**
     * @ignore
     */
    this.drawingsByPage = {};
    /**
     * @ignore
     */
    this.pageSizes = {};

    this.SCALE_FACTOR = 1.5; //2.77;

  
     /**
     * @ignore
     */
    this.renderingQueue = Promise.resolve();

    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
   
    this.canvas.classList.add("pdm-Canvas");
    this.canvas2.classList.add("pdm-CanvasBack");
    this.initializeCanvas();
    this.container.appendChild(this.toolbar);
    this.container.appendChild(this.canvas);
    this.container.appendChild(this.canvas2);

    this.initDoc(pdfUrl, maskData);
  }

  /**
   * @ignore
   */
  createToolbar() {
    const toolbar = document.createElement("div");
    toolbar.className = "pdm-toolbar";

    const pageNumberInput = document.createElement("input");
    pageNumberInput.type = "text";
    pageNumberInput.id = "pdm-pageNumber";
    pageNumberInput.value = "1";
    pageNumberInput.addEventListener("input", (e) => {
      const newPageNum = parseInt(e.target.value);
      this.renderPage(newPageNum);
    });

    const drawRectangleButton = this.createButton("drawRectangle", squareImg, () => this.setDrawingMode("rectangle"), "Mark Rectangle");
    const drawFingerPrintButton = this.createButton("drawFingerPrint", fingerPrintImg, () => this.setDrawingMode("finger"), "Mark Finger Print");
    const drawQrButton = this.createButton("drawQr", qrImg, () => this.setDrawingMode("qr"), "Mark QR Code");
    const deleteObjectButton = this.createButton("deleteObject", trashImg, () => this.setDrawingMode("deleteObject"), "Delete Marking");
    const defaultCursorButton = this.createButton("defaultCursor", pointerImg, () => this.setDrawingMode(null), "Reset Interaction");
    const nextPageButton = this.createButton("nextPage", nextImg, () => this.navigateToPage(this.pageNum + 1), "Next Page");
    const prevPageButton = this.createButton("prevPage", previousImg, () => this.navigateToPage(this.pageNum - 1), "Previous Page");
    const drawTextButton = this.createButton("drawText", textImg, () => this.setDrawingMode("text"), "Draw Text");
    const drawLineButton = this.createButton("drawLine", lineImg, () => this.setDrawingMode("line"), "Draw Line");
    const drawFreeHandButton = this.createButton("drawFreehand", freehandImg, () => this.setDrawingMode("freehand"), "Draw Free Hand");
    const totalPagesLabel = document.createElement("span");

    toolbar.appendChild(prevPageButton);
    toolbar.appendChild(pageNumberInput);
    toolbar.appendChild(totalPagesLabel);
    toolbar.appendChild(nextPageButton);
    toolbar.appendChild(drawRectangleButton);
    toolbar.appendChild(drawFingerPrintButton);
    toolbar.appendChild(drawQrButton);
    toolbar.appendChild(drawTextButton);
    toolbar.appendChild(drawLineButton);
    toolbar.appendChild(drawFreeHandButton);
    toolbar.appendChild(deleteObjectButton);
    toolbar.appendChild(defaultCursorButton);

    return toolbar;
  }

  initDoc(pdfUrl, maskData) {

    this.drawingMode = null;
    this.drawing = false;
    this.startX = 0;
    this.startY = 0;
    this.drawingsByPage = {};
    this.pageSizes = {};
    this.pageNum = 1;

    if (maskData) {
      this.initDrawings(maskData);
    }
    if(pdfUrl){
    pdfjsLib.getDocument(pdfUrl).promise.then((pdfDoc) => {
      this.pdfDoc = pdfDoc;
      this.toolbar.querySelector("#pdm-pageNumber").setAttribute("max", pdfDoc.numPages);
      this.renderPage(this.pageNum);
    });
  }

  }

  /**
   * @ignore
   */
  createButton(id, iconName, clickHandler, iconText = "") {
    const button = document.createElement("button");
    button.id = id;
    button.innerHTML = `<img src="${iconName}" title="${iconText}" style="width: 20px; height: 20px;" />`;
    // button.classList.add('pdm-background-sel');

    button.addEventListener("click", clickHandler);
    return button;
  }

  /**
   * @ignore
   */
  renderPage(num) {
    this.renderingQueue = this.renderingQueue.then(() => {
      if (num < 1) {
        num = 1;
      } else if (num > this.pdfDoc.numPages) {
        num = this.pdfDoc.numPages;
      }

      return this.pdfDoc.getPage(num).then((page) => {
       
        const viewport = page.getViewport({ scale: this.SCALE_FACTOR });
        this.pageNum = num;

        this.toolbar.querySelector("#pdm-pageNumber").value = num;
        this.toolbar.querySelector("span").textContent = `/ ${this.pdfDoc.numPages}`;

        this.canvas.width = viewport.width;
        this.canvas.height = viewport.height;
        this.canvas2.width = viewport.width;
        this.canvas2.height = viewport.height;
        this.canvas2.left = viewport.left;
        this.canvas2.top = viewport.top;

        const pageSizeObj = this.pageSizes[num] || {};
        if (!pageSizeObj.hasOwnProperty("PageHeight")) {
          pageSizeObj.PageHeight = Math.round(viewport.height);
          pageSizeObj.PageWidth = Math.round(viewport.width);
          this.pageSizes[num] = pageSizeObj;
        }

        this.pdfContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
        const renderTask = page.render({ canvasContext: this.pdfContext, viewport: viewport });

        return renderTask.promise.then(() => {
          this.drawDrawings(this.pageNum);
        });
      });
    });
  }

  /**
   * Gets the image data of a PDF page as a Data URL.
   *
   * @param {number} pageIndex - Index of the page to capture.
   * @param {number} quality - Image quality (0-1).
   * @returns {Promise<{ imageDataUrl: string, width: number, height: number }>} - Promise resolving with image data.
   */

  async getPageAsImage(pageIndex,quality) {
    // const pdfDoc = await pdfjsLib.getDocument({ data: pdfBytes }).promise;
    const page = await this.pdfDoc.getPage(pageIndex + 1); // Note: Page indices in PDF.js start from 1

    const scale = 1.3;
    const scale_factor = this.SCALE_FACTOR / scale;
    const viewport = page.getViewport({ scale: 1.3 });

    // Prepare canvas using PDF page dimensions
    const canvas = createCanvas(viewport.width, viewport.height);
    const context = canvas.getContext("2d");

    // Render PDF page into canvas context
    await page.render({ canvasContext: context, viewport }).promise;

    const drawings = this.drawingsByPage[pageIndex + 1] || [];
    drawings.forEach((draw) => {
      if (draw.type === "rectangle") {
        context.fillStyle = "rgba(0, 0, 0)";
        context.fillRect(draw.x / scale_factor, draw.y / scale_factor, draw.width / scale_factor, draw.height / scale_factor);
      } else if (draw.type === "finger") {
        context.fillStyle = "rgba(0, 0, 0)";
        context.beginPath();

        const centerX = draw.x / scale_factor+ draw.width / scale_factor / 2;
        const centerY = draw.y / scale_factor + draw.height / scale_factor / 2;
        const radiusX = Math.abs(draw.width / scale_factor / 2);
        const radiusY = Math.abs(draw.height / scale_factor / 2);

        context.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);

        context.fill();
        context.closePath();
      } else if (draw.type === "qr") {
        context.fillStyle = "rgba(0, 0, 0)";
        context.fillRect(draw.x / scale_factor, draw.y / scale_factor, draw.width / scale_factor, draw.height / scale_factor);
      }
    });

    // Convert canvas to image data URL
    const imageDataUrl = canvas.toDataURL("image/jpeg", quality);
    canvas.remove();
    return { imageDataUrl: imageDataUrl, width: viewport.width/scale, height: viewport.height/scale }; //imageDataUrl;
  }

  /**
   * @ignore
   */
  navigateToPage(pageNum) {
    this.renderPage(pageNum);

    const customEventWithData = new CustomEvent('pageNavigation', {
      detail: {
        page: pageNum
      }
    });
    this.eventTarget.dispatchEvent(customEventWithData);

  }
  /**
   * @ignore
   */
  initializeCanvas() {
    this.drawDrawings(this.pageNum);

    //this.canvas2.addEventListener('wheel', this.handleWheel.bind(this));
    // this.canvas.addEventListener('wheel', this.handleWheel.bind(this));
    this.canvas2.addEventListener("mousedown", this.handleMouseDown.bind(this));
    this.canvas2.addEventListener("mousemove", this.handleMouseMove.bind(this));
    this.canvas2.addEventListener("mouseup", this.handleMouseUp.bind(this));


    this.canvas2.addEventListener('touchstart', this.handleMouseDown.bind(this));
    this.canvas2.addEventListener('touchmove', this.handleMouseMove.bind(this));
    this.canvas2.addEventListener('touchend', this.handleMouseUp.bind(this));

  }
  /**
   * @ignore
   */
  drawDrawings(pageNum) {
    const drawings = this.drawingsByPage[pageNum] || [];
    drawings.forEach((draw) => {
      if (draw.type === "rectangle") {
        this.drawRectangle(draw);
      } else if (draw.type === "finger") {
        this.drawEllipse(draw);
      } else if (draw.type === "qr") {
        this.drawRectangle(draw);
      } else if (draw.type === "text") {
        this.drawText(draw);
      } else if (draw.type === "line") {
        this.drawLine(draw);
      }else if (draw.type === "freehand") {
        this.drawFreehand(draw);
      }
    });
  }
  
    /**
   * @ignore
   */
    setDrawingMode(mode) {
      this.drawingMode = mode;
      this.canvas2.style.display = "block";
  
      this.toolbar.querySelectorAll(".pdm-toolbar button").forEach((button) => {
        button.classList.remove("pdm-background-sel");
      });
      if (this.drawingMode === "deleteObject") {
        this.toolbar.querySelector("#deleteObject").classList.add("pdm-background-sel");
      } else if (this.drawingMode === "rectangle") {
        this.toolbar.querySelector("#drawRectangle").classList.add("pdm-background-sel");
      } else if (this.drawingMode === "ellipse") {
        this.toolbar.querySelector("#drawEllipse").classList.add("pdm-background-sel");
      } else if (this.drawingMode === "finger") {
        this.toolbar.querySelector("#drawFingerPrint").classList.add("pdm-background-sel");
      } else if (this.drawingMode === "qr") {
        this.toolbar.querySelector("#drawQr").classList.add("pdm-background-sel");
      } else if (this.drawingMode === "line") {
        this.toolbar.querySelector("#drawLine").classList.add("pdm-background-sel");
      } else if (this.drawingMode === "freehand") {
        this.toolbar.querySelector("#drawFreehand").classList.add("pdm-background-sel");
      }else if (this.drawingMode === "text") {
        this.toolbar.querySelector("#drawText").classList.add("pdm-background-sel");
        
      }
    }
  

  /**
   * @ignore
   */
  handleMouseDown(e) {
    if(this.drawingMode !=null){
      e.preventDefault();
    }
   
    if (this.drawingMode === "deleteObject") {
      const pointer = this.getCanvasPointer(e);
      const intersectedObject = this.findIntersectedObject(pointer);

      if (intersectedObject) {
        this.removeIntersectedObject(intersectedObject);
        this.renderPage(this.pageNum);
      }
    } else if (this.drawingMode !== null) {
      this.drawing = true;
      const pointer = this.getCanvasPointer(e);
      this.startX = pointer.x;

      this.startY = pointer.y;
      this.lastX = pointer.x;
      this.lastY = pointer.y;
      if (this.drawingMode === "freehand") {
        this.freehandPoints = [];
        this.freehandPoints.push({ x: pointer.x, y: pointer.y });
      }
    }
    this.pdfContext2.clearRect(0, 0, this.canvas2.width, this.canvas2.height);
  }

  /**
   * @ignore
   */
  handleMouseMove(e) {
    if(this.drawingMode !=null){
      e.preventDefault();
    }
    if (!this.drawing) return;

    const pointer = this.getCanvasPointer(e);
    const width = pointer.x - this.startX;
    const height = pointer.y - this.startY;

    this.lastX = pointer.x;
    this.lastY = pointer.y;
    // console.log(pointer);
    const draw = { type: this.drawingMode, x: this.startX, y: this.startY, width, height , endX: pointer.x, endY: pointer.y};
    if (this.drawingMode === "rectangle") {
      this.drawRectangle2(draw);
    } else if (this.drawingMode === "finger") {
      this.drawEllipse2(draw);
    } else if (this.drawingMode === "qr") {
      this.drawRectangle2(draw);
    }
    else if (this.drawingMode === "line") {
     this.drawLine2(draw);
    }
    else if (this.drawingMode === "freehand") {
      this.freehandPoints.push({ x: pointer.x, y: pointer.y });
      this.drawFreehand2(draw);
      this.startX = pointer.x;
      this.startY = pointer.y;
     }
  }


  /**
   * @ignore
   */
  handleMouseUp() {
    if(this.drawingMode !=null){
     // e.preventDefault();
    }
    console.log("mouseUp");
    if (this.drawingMode !== null) {
      this.pdfContext2.clearRect(0, 0, this.canvas2.width, this.canvas2.height);
       this.drawing = false;
      // this.canvas2.style.display = "none";
      // this.toolbar.querySelectorAll(".pdm-toolbar button").forEach((button) => {
      //   button.classList.remove("pdm-background-sel");
      // });
      //const pointer = this.getCanvasPointer(event);
      const width = this.lastX- this.startX;
      const height = this.lastY - this.startY;

      const draw = { type: this.drawingMode, x: this.startX, y: this.startY, width, height ,  endX: this.lastX, endY: this.lastY};
      if (this.drawingMode === "text") {
        const text = prompt("Enter text:");
        if (text) {
          draw.text = text;
        }
      }
      else if (this.drawingMode === "freehand") {

        draw.freehandPoints =this.freehandPoints; // this.simplifyPath(this.freehandPoints,0.2);
        console.log(draw.freehandPoints.length,this.freehandPoints.length);
        this.freehandPoints=[];
      }
      this.updateDrawings(draw);
      this.renderPage(this.pageNum);


    }
  }


  /**
   * @ignore
   */
  handleWheel(e) {
    const container = this.container; // Assuming this.container is the container element for the pages
    const direction = Math.sign(e.deltaY);

    // Check if scrolling down and at the bottom of the current page
    if (direction === 1 && container.scrollTop + container.clientHeight >= container.scrollHeight) {
      if (this.pageNum < this.pdfDoc.numPages) {
        this.pageNum++;
        this.renderPage(this.pageNum);
      }
    }
    // Check if scrolling up and at the top of the current page
    else if (direction === -1 && container.scrollTop === 0) {
      if (this.pageNum > 1) {
        this.pageNum--;
        this.renderPage(this.pageNum);
      }
    }
  }
  /**
   * @ignore
   */
  findIntersectedObject(pointer) {
    const drawings = this.drawingsByPage[this.pageNum] || [];

    for (const draw of drawings) {
      if (
        (draw.type === "rectangle" || draw.type === "qr") &&
        pointer.x >= draw.x &&
        pointer.x <= draw.x + draw.width &&
        pointer.y >= draw.y &&
        pointer.y <= draw.y + draw.height
      ) {
        return draw;
      } else if ((draw.type === "ellipse" || draw.type === "finger") && this.isPointInsideEllipse(pointer.x, pointer.y, draw)) {
        return draw;
      }
    }

    return null;
  }
  /**
   * @ignore
   */
  isPointInsideEllipse(x, y, draw) {
    const centerX = draw.x + draw.width / 2;
    const centerY = draw.y + draw.height / 2;
    const normalizedX = (x - centerX) / (draw.width / 2);
    const normalizedY = (y - centerY) / (draw.height / 2);

    return normalizedX * normalizedX + normalizedY * normalizedY <= 1;
  }
  /**
   * @ignore
   */
  removeIntersectedObject(intersectedObject) {
    const drawings = this.drawingsByPage[this.pageNum] || [];
    const updatedDrawings = drawings.filter((draw) => draw !== intersectedObject);
    this.drawingsByPage[this.pageNum] = updatedDrawings;
    
    
    const customEventWithData = new CustomEvent('updatedDrawings', {
      detail: {
        page: this.pageNum,
        data:  this.drawingsByPage[this.pageNum]
      }
    });
    this.eventTarget.dispatchEvent(customEventWithData);

  }
  
  /**
   * @ignore
   */
  drawRectangle(draw) {
    this.pdfContext.fillStyle = "rgba(255, 0, 0, 0.3)";
    this.pdfContext.fillRect(draw.x, draw.y, draw.width, draw.height);
  }
  /**
   * @ignore
   */
  drawEllipse(draw) {
    this.pdfContext.fillStyle = "rgba(0, 0, 255, 0.3)";
    this.pdfContext.beginPath();

    const centerX = draw.x + draw.width / 2;
    const centerY = draw.y + draw.height / 2;
    const radiusX = Math.abs(draw.width / 2);
    const radiusY = Math.abs(draw.height / 2);

    this.pdfContext.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);

    this.pdfContext.fill();
    this.pdfContext.closePath();
  }
  /**
   * @ignore
   */
  drawRectangle2(draw) {
    this.pdfContext2.clearRect(0, 0, this.canvas2.width, this.canvas2.height);
    this.pdfContext2.fillStyle = "rgba(255, 0, 0, 0.5)";
    this.pdfContext2.fillRect(draw.x, draw.y, draw.width, draw.height);
  }
  /**
   * @ignore
   */
  drawEllipse2(draw) {
    this.pdfContext2.clearRect(0, 0, this.canvas2.width, this.canvas2.height);
    this.pdfContext2.fillStyle = "rgba(0, 0, 255, 0.5)";
    this.pdfContext2.beginPath();

    const centerX = draw.x + draw.width / 2;
    const centerY = draw.y + draw.height / 2;
    const radiusX = Math.abs(draw.width / 2);
    const radiusY = Math.abs(draw.height / 2);

    this.pdfContext2.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);

    this.pdfContext2.fill();
    this.pdfContext2.closePath();
  }

  drawText(draw) {
    this.pdfContext.fillStyle = "rgba(0, 0, 255, 0.5)";
    this.pdfContext.font = "18px Arial";
    this.pdfContext.fillText(draw.text, draw.x, draw.y);
  }

  drawLine(draw) {
    this.pdfContext.strokeStyle = "rgba(0, 0, 255, 0.5)";
    this.pdfContext.lineWidth = 5;
    this.pdfContext.beginPath();
    this.pdfContext.moveTo(draw.x, draw.y);
    this.pdfContext.lineTo(draw.endX, draw.endY);
    this.pdfContext.stroke();
    this.pdfContext.closePath();
  }

  drawLine2(draw) {
    this.pdfContext2.clearRect(0, 0, this.canvas2.width, this.canvas2.height);
    this.pdfContext2.strokeStyle = "rgba(0, 0, 255, 0.5)";
    this.pdfContext2.lineWidth = 5;
    this.pdfContext2.beginPath();
    this.pdfContext2.moveTo(draw.x, draw.y);
    this.pdfContext2.lineTo(draw.endX, draw.endY);
    this.pdfContext2.stroke();
    this.pdfContext2.closePath();
  }

  drawFreehand2(draw) {
    // this.pdfContext2.clearRect(0, 0, this.canvas2.width, this.canvas2.height);
    this.pdfContext2.strokeStyle = "rgba(0, 0, 255, 0.5)";
    this.pdfContext2.lineWidth = 5;
    this.pdfContext2.beginPath();
    this.pdfContext2.moveTo(draw.x, draw.y);
    this.pdfContext2.lineTo(draw.endX, draw.endY);
    this.pdfContext2.stroke();
    this.pdfContext2.closePath();
  }

  drawFreehand(draw) {

    this.pdfContext.strokeStyle = "rgba(0, 0, 255, 0.5)";
    this.pdfContext.lineWidth = 5;
    this.pdfContext.beginPath();
    for(let i = 1; i < draw.freehandPoints.length - 1; i++) {
      this.pdfContext.moveTo(draw.freehandPoints[i-1].x, draw.freehandPoints[i-1].y);
      this.pdfContext.lineTo(draw.freehandPoints[i].x, draw.freehandPoints[i].y);
    }   
    this.pdfContext.stroke();
    this.pdfContext.closePath();
  }

   simplifyPath(points, threshold) {
    if (points.length < 3) return points;

    const simplified = [points[0]];

    for (let i = 1; i < points.length - 2; i++) {
        const prev = points[i - 1];
        const current = points[i];
        const next = points[i + 1];

        // Calculate the distance from the current point to the line formed by prev and next
        const dist = this.perpendicularDistance(current, prev, next);

        if (dist > threshold) {
            simplified.push(current);
        }
    }

    simplified.push(points[points.length - 1]);

    return simplified;
}

 perpendicularDistance(point, lineStart, lineEnd) {
    const x0 = point.x;
    const y0 = point.y;
    const x1 = lineStart.x;
    const y1 = lineStart.y;
    const x2 = lineEnd.x;
    const y2 = lineEnd.y;

    // Perpendicular distance from point to line
    return Math.abs((y2 - y1) * x0 - (x2 - x1) * y0 + x2 * y1 - y2 * x1) /
           Math.sqrt(Math.pow(y2 - y1, 2) + Math.pow(x2 - x1, 2));
}


 getNormalizedCoordinates(event) {
    const rect = canvas.getBoundingClientRect();
    const x = (event.clientX - rect.left) / rect.width;
    const y = (event.clientY - rect.top) / rect.height;
    return [x, y];
}
  /**
   * @ignore
   */
  initDrawings(maskData) {
    maskData.data.forEach((data) => {
      const pageNo = data.PageNumber;
      const pageSizeObj = this.pageSizes[pageNo] || {};
      pageSizeObj.PageHeight = data.PageHeight;
      pageSizeObj.PageWidth = data.PageWidth;
      this.pageSizes[pageNo] = pageSizeObj;

      data.AAdharandPan.forEach((apData) => {
        const draw = { type: "rectangle", x: apData[0], y: apData[1], width: apData[2] - apData[0], height: apData[3] - apData[1] };
        const drawings = this.drawingsByPage[pageNo] || [];
        drawings.push(draw);
        this.drawingsByPage[pageNo] = drawings;
      });

      data.fingerprints.forEach((apData) => {
        const draw = { type: "finger", x: apData[0], y: apData[1], width: apData[2] - apData[0], height: apData[3] - apData[1] };
        const drawings = this.drawingsByPage[pageNo] || [];
        drawings.push(draw);
        this.drawingsByPage[pageNo] = drawings;
      });

      data.QRCodes.forEach((apData) => {
        const draw = { type: "qr", x: apData[0], y: apData[1], width: apData[2] - apData[0], height: apData[3] - apData[1] };
        const drawings = this.drawingsByPage[pageNo] || [];
        drawings.push(draw);
        this.drawingsByPage[pageNo] = drawings;
      });
    });
  }
  /**
   * @ignore
   */
  updateDrawings(newDraw) {
    if (newDraw.type === "deleteObject") {
      return;
    }
    const drawings = this.drawingsByPage[this.pageNum] || [];
    drawings.push(newDraw);
    this.drawingsByPage[this.pageNum] = drawings;

    const customEventWithData = new CustomEvent('updatedDrawings', {
      detail: {
        page: this.pageNum,
        data:  this.drawingsByPage[this.pageNum]
      }
    });
    this.eventTarget.dispatchEvent(customEventWithData);

    // console.log(this.drawingsByPage);
  }
  /**
   * @ignore
   */
  getCanvasPointer(e) {
    const rect = this.canvas.getBoundingClientRect();
    if (e.touches) {
      return {
        x: e.touches[0].clientX - rect.left,
        y: e.touches[0].clientY - rect.top,
      };
    }
    else{
    return {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
  }
  }

  /**
   * Retrieves masking data for each page in the PDF document.
   *
   * @typedef {Object} MaskingData
   * @property {number} PageNumber - The page number.
   * @property {number} PageHeight - The height of the page.
   * @property {number} PageWidth - The width of the page.
   * @property {Array<Array<number>>} AAdharandPan - Array of rectangle coordinates for AAdhar and Pan markings.
   * @property {Array<Array<number>>} fingerprints - Array of ellipse coordinates for fingerprint markings.
   * @property {Array<Array<number>>} QRCodes - Array of rectangle coordinates for QR Code markings.
   *
   * @returns {Array<MaskingData>} - Array containing masking data for each page.
   */

  getMaskData() {
    let data = [];
    for (let i = 1; i <= this.pdfDoc.numPages; i++) {
      let pageData = {};
      pageData.PageNumber = i;
      pageData.PageHeight = this.pageSizes[i]?.PageHeight;
      pageData.PageWidth = this.pageSizes[i]?.PageWidth;
      pageData.AAdharandPan = [];
      pageData.fingerprints = [];
      pageData.QRCodes = [];
      pageData.Lines = [];
      pageData.Text = [];

      let drawings = this.drawingsByPage[i] || [];
      drawings.forEach((draw) => {
        if (draw.type === "rectangle") {
          let x1 = Math.round(draw.x);
          let y1 = Math.round(draw.y);
          let x2 = Math.round(draw.x + draw.width);
          let y2 = Math.round(draw.y + draw.height);

          pageData.AAdharandPan.push([x1, y1, x2, y2]);
        }
      });

      drawings.forEach((draw) => {
        if (draw.type === "qr") {
          let x1 = Math.round(draw.x);
          let y1 = Math.round(draw.y);
          let x2 = Math.round(draw.x + draw.width);
          let y2 = Math.round(draw.y + draw.height);

          pageData.QRCodes.push([x1, y1, x2, y2]);
        }
      });
      drawings.forEach((draw) => {
        if (draw.type === "finger") {
          let x1 = Math.round(draw.x);
          let y1 = Math.round(draw.y);
          let x2 = Math.round(draw.x + draw.width);
          let y2 = Math.round(draw.y + draw.height);

          pageData.fingerprints.push([x1, y1, x2, y2]);
        }
      });
      drawings.forEach((draw) => {
        if (draw.type === "line") {
          let x1 = Math.round(draw.x);
          let y1 = Math.round(draw.y);
          let x2 = Math.round(draw.endX);
          let y2 = Math.round(draw.endY);

          pageData.Lines.push([x1, y1, x2, y2]);
        }
      });
      drawings.forEach((draw) => {
        if (draw.type === "freehand") {
          for(let i = 1; i < draw.freehandPoints.length - 1; i++) {

            let x1 = Math.round(draw.freehandPoints[i-1].x);
            let y1 = Math.round(draw.freehandPoints[i-1].y);
            let x2 = Math.round(draw.freehandPoints[i].x);
            let y2 = Math.round(draw.freehandPoints[i].y);
  

            pageData.Lines.push([x1, y1, x2, y2]);
          }   

        

         
        }
      });

      drawings.forEach((draw) => {
        if (draw.type === "text") {
          let x1 = Math.round(draw.x);
          let y1 = Math.round(draw.y);
        

          pageData.Text.push([x1, y1, draw.text]);
        }
      });

      data.push(pageData);
    }
    return data;
  }

  /**
   * Asynchronously generates a masked PDF by overlaying masking data. The pages are converted to image before masking.
   *
   * @param {number} [quality] - Optional quality of the generated PDF (between 0 and 1). Default value is 0.7
   * @returns {Promise<Uint8Array>} - A promise that resolves to the modified PDF byte data.
   */
  async getMaskedPdf( quality=0.7) {

     const pdfBytes = await this.pdfDoc.getData();
   
    const modifiedPDFDoc = await PDFDocument.create();
    const RBinfo = this.getMaskData();

    for (let i = 0; i < RBinfo.length; i++) {
      // Get the image data URL using PDF.js
      const { imageDataUrl, width, height } = await this.getPageAsImage( i,quality);

      const modifiedPDFPage = modifiedPDFDoc.addPage([width, height]);

      const pngImageBytes = await fetch(imageDataUrl).then((res) => res.arrayBuffer());
      const pngImage = await modifiedPDFDoc.embedJpg(pngImageBytes);

      modifiedPDFPage.drawImage(pngImage, {
        x: 0,
        y: 0,
        width: modifiedPDFPage.getWidth(),
        height: modifiedPDFPage.getHeight(),
      });
    }

    // Save the modified PDF to a Uint8Array
    const modifiedPdfBytes = await modifiedPDFDoc.save();

    // Now you can use modifiedPdfBytes as needed, such as saving to a file or displaying on a webpage
    return modifiedPdfBytes;
  }

  /**
   *
   * Masks the PDF by drawing rectange on the PDF. The actual text may be available in the PDF.
   * It is only useful for printing.
   *
   * @ignore
   *
   */

  async getMaskedPDF2() {
    const pdfData = await this.pdfDoc.getData();
    const pdfLibDoc = await PDFDocument.load(pdfData);

    const pages = pdfLibDoc.getPages();
    const RBinfo = this.getMaskData();

    for (let i = 0; i < RBinfo.length; i++) {
      const pageno = RBinfo[i].PageNumber;
      const pdfpage = pages[i];

      const { width, height } = pdfpage.getSize();

      const Hscale = (Number(RBinfo[i].PageWidth) / width).toFixed(2);
      const Vscale = (Number(RBinfo[i].PageHeight) / height).toFixed(2);

      if (RBinfo[i].AAdharandPan != null) {
        for (let j = 0; j < RBinfo[i].AAdharandPan.length; j++) {
          console.log(RBinfo[i].AAdharandPan[j]);
          const H = Math.round((Number(RBinfo[i].AAdharandPan[j][3]) - Number(RBinfo[i].AAdharandPan[j][1])) / Vscale);
          pdfpage.drawRectangle({
            x: Math.round(Number(RBinfo[i].AAdharandPan[j][0]) / Hscale),
            y: Math.round(height - Number(RBinfo[i].AAdharandPan[j][1]) / Vscale) - H,
            width: Math.round((Number(RBinfo[i].AAdharandPan[j][2]) - Number(RBinfo[i].AAdharandPan[j][0])) / Hscale),
            height: H,
            color: rgb(1, 0, 0),
            opacity: 0.5
          });
        }
      }

      if (RBinfo[i].fingerprints != null) {
        for (let j = 0; j < RBinfo[i].fingerprints.length; j++) {
          console.log(RBinfo[i].fingerprints[j]);
          const H = Math.round((Number(RBinfo[i].fingerprints[j][3]) - Number(RBinfo[i].fingerprints[j][1])) / Vscale);
          pdfpage.drawRectangle({
            x: Math.round(Number(RBinfo[i].fingerprints[j][0]) / Hscale),
            y: Math.round(height - Number(RBinfo[i].fingerprints[j][1]) / Vscale) - H,
            width: Math.round((Number(RBinfo[i].fingerprints[j][2]) - Number(RBinfo[i].fingerprints[j][0])) / Hscale),
            height: H,
            color:rgb(1, 0, 0),
            opacity: 0.5
          });
        }
      }

      if (RBinfo[i].QRCodes != null) {
        for (let j = 0; j < RBinfo[i].QRCodes.length; j++) {
          console.log("QRCode" + RBinfo[i].QRCodes[j]);
          const H = Math.round((Number(RBinfo[i].QRCodes[j][3]) - Number(RBinfo[i].QRCodes[j][1])) / Vscale);
          pdfpage.drawRectangle({
            x: Math.round(Number(RBinfo[i].QRCodes[j][0]) / Hscale),
            y: Math.round(height - Number(RBinfo[i].QRCodes[j][1]) / Vscale) - H,
            width: Math.round((Number(RBinfo[i].QRCodes[j][2]) - Number(RBinfo[i].QRCodes[j][0])) / Hscale),
            height: H,
            color: rgb(1, 0, 0),
            opacity: 0.5
          });
        }
      }

      if (RBinfo[i].Lines != null) {
        for (let j = 0; j < RBinfo[i].Lines.length; j++) {
          
          pdfpage.drawLine({
            start: { x: Math.round(Number(RBinfo[i].Lines[j][0]) / Hscale),
                    y: Math.round(height - Number(RBinfo[i].Lines[j][1]) / Vscale)  },
            end: { x: Math.round(Number(RBinfo[i].Lines[j][2]) / Hscale),
                  y: Math.round(height - Number(RBinfo[i].Lines[j][3]) / Vscale) },
            color: rgb(1, 0, 0),
            opacity: 0.5
          });
        }
      }

      if (RBinfo[i].Text != null) {
        for (let j = 0; j < RBinfo[i].Text.length; j++) {
          
          pdfpage.drawText(RBinfo[i].Text[j][2],{
            x: Math.round(Number(RBinfo[i].Text[j][0]) / Hscale),
            y: Math.round(height- Number(RBinfo[i].Text[j][1]) / Vscale),
            size: 12,
            color: rgb(1, 0, 0),
            opacity: 0.5
          });
        }
      }

    }
    const pdfBytes = await pdfLibDoc.save();

    return pdfBytes;
  }

  /**
   * Downloads a PDF document.
   *
   * @param {Uint8Array} pdfBytes - PDF document bytes.
   */
  download(pdfBytes) {
    const blob = new Blob([pdfBytes], { type: "application/pdf" });

    // Create a download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);

    // Set the download attribute with the desired filename
    link.download = "downloaded.pdf";

    // Append the link to the document and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  }
}

export { PDFMarker };
